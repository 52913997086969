<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">View Record</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row">
                <div class="col-md-7">
                  <div class="row mb-3">
                    <label for="name" class="col-md-3 form-label">
                      Employee Name
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="
                          behaviorAssessment.behavior_assessments.employee_name
                        "
                        disabled
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Employee ID
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="
                          behaviorAssessment.behavior_assessments.employee_id
                        "
                        disabled
                      />
                    </div>
                  </div>

                  <div class="row mb-2">
                    <label for="short_name" class="col-md-3 form-label">
                      Program Name
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        v-model="
                          behaviorAssessment.behavior_assessments
                            .program_short_name
                        "
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div class="row col-md-5">
                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Batch
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="
                          behaviorAssessment.behavior_assessments
                            .program_batch_no
                        "
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="date" class="col-md-3 form-label">
                      Joined Date
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="
                          behaviorAssessment.behavior_assessments.join_date
                        "
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label
                      for="evaluating_criteria"
                      class="col-md-3 form-label"
                    >
                      Assessor
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="behaviorAssessment.assessor_employee_name"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="progress mt-3"
                style="height: 0.5px; background-color: #ccc !important"
              >
                <div class="progress-bar" role="progressbar"></div>
              </div>

              <div class="row g-3 mt-3">
                <div class="col-sm-4">
                  <h4 class="mb-3">Criteria</h4>
                </div>
                <div class="col-sm-4">
                  <h4 class="mb-3">Scores:*</h4>
                </div>
              </div>
                
                <div
                  class="row g-3"
                  v-for="(cri, index) in criteria"
                  :key="index"
                >
                  <div class="col-sm-4">
                    <p class="mb-3 pt-2 pl-2">{{ cri.name }}</p>
                  </div>
                  <div class="col-sm-4">
                    <div class="mt-2">
                        <input
                          type="text"
                          class="form-control"
                          v-model="cri.answer_score"
                          disabled
                        />
                    </div>
                  </div>
                
                </div>
              <div class="row g-3">
                <div class="col-sm-4">Total Score</div>
                <div class="col-sm-4">
                  <input type="text" class="form-control mt-2" v-model="behaviorAssessment.total_score" disabled />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      behaviorAssessment: [],
      criteria: [],
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
    };
  },

  methods: {
    async getAssessment() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v1/hrd-behavior-assessment/${this.$route.params.id}`
        )
        .then((response) => {
          this.behaviorAssessment = response.data.data;
          const data = this.behaviorAssessment.criterias;

          const cleanedString = data.replace(/\\/g, "");
          this.criteria = JSON.parse(cleanedString);
          this.loading = false;
          this.$Progress.finish();
        });
    },
  },

  created() {
    this.getAssessment();
  },
};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
.selectScore {
  padding: 0.45rem 0.9rem;
}
</style>
